/**
 * App demo
 */
import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player'

class AppDemo extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         modal: false
      };

      this.toggle = this.toggle.bind(this);
   }

   toggle() {
      this.setState(prevState => ({
         modal: !prevState.modal
      }));
   }

   render() {
      return (
         <div>
            <Button className="btn py-2 rounded-btn primary-bdr-btn fs-12 text-space fw-700 text-uppercase text-space" onClick={this.toggle}>3 Minute Demo</Button>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
               <ModalBody>
                  <Button color="primary" className="close-btn" onClick={this.toggle}>
                     <i className="fas fa-times"></i>
                  </Button>
                  <ReactPlayer url='https://player.vimeo.com/video/336265854' playing muted />
               </ModalBody>
            </Modal>
         </div>
      );
   }
}

export default AppDemo;

