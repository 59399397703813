/**
 * preview slider component
 */
import React from 'react';
import AppDemo from './AppDemo';

const parteners = [
   {
      thumb: "shopify-white.png",
      path: "https://www.shopify.in/"
   },
   {
      thumb: "click-funnel-white.png",
      path: "https://www.clickfunnels.com/"
   },

   {
      thumb: "wix-white.png",
      path: "https://www.wix.com/"
   },
   {
      thumb: "square-space-white.png",
      path: "https://www.squarespace.com/"
   },
]

class LoginPreviewPanel extends React.Component {

   render() {
      return (
         <div className="sh-preview-panel h-100"
            style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + "/images/preview-panel.jpg )" }}
         >
            <div className="content-section d-flex justify-content-center align-items-center px-5">
               <div className="text-center">
                  {/* <span className="text-uppercase fw-600 fs-12 mb-3 d-block text-space">SalesHangry Enterprise</span> */}
                  <h5 className="text-uppercase fw-600 mb-4 text-space">Engage users fast. Convert faster. All automatically.</h5>
                  <p className="font-italic mb-4 py-2">Our goal is to make the process of selling a customer shorter and automated.</p>
                  {/* <button className="btn py-2 rounded-btn primary-bdr-btn fs-12 text-space fw-700 text-uppercase text-space">5 Minute Demo</button> */}
                  <AppDemo />
               </div>
            </div>
            <div className="panel-footer d-flex">
               {/* <p className="text-uppercase fs-12">trusted by the world's smartest companies</p> */}
               {parteners.map((partener, index) => {
                  return (
                     <div key={index} className="sh-partener-wrap">
                        <a href={partener.path} target="_blank" rel="noopener noreferrer" className="d-inline-block">
                           <img src={process.env.PUBLIC_URL + `/images/${partener.thumb}`} alt="icons" width="100" />
                        </a>
                     </div>
                  )
               })}
            </div>
         </div>
      );
   }
}

export default LoginPreviewPanel;