/**
 * Login page
 */
import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import SocialButton from '../../Components/SocialButton';
import * as LoginService from '../../Services/LoginService';
import * as FormValidationService from '../../Services/FormValidationService';
import { Form, FormGroup, Label, Input,CustomInput,Col } from 'reactstrap';
import LoginPreviewPanel from '../../Components/LoginPreviewPanel';

class Login extends Component {

   // facebookAppId = '834781667004529'; // Dev facebook key
   // facebookAppId = '679943619011315';  // Dev facebook key
   facebookAppId = '351328215521843';   // Live facebook key

   // Dev google key
   // googleClientId = '47239637118-gssjqi1qmqhjs1ih1kgbmul0lqbh8m2a.apps.googleusercontent.com'
   // googleClientId = '231688632496-ggu8ob36vrtkonmf27ulens1cmu26bpf.apps.googleusercontent.com';
   //Live google key
   googleClientId = '380766611471-ffqetkribho0ep6hj9demf6gi9c5o5it.apps.googleusercontent.com';

   months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
   years = [];

   constructor(props) {
      super(props);
      this.state = {
         ip: '',
         loading: false,
         email: '',
         password: '',
         formErrors: {
            blankEmail: false,
            validEmail: false,
            blankPassword: false,
            validCardNumber: false,
            validCardCVV: false,
            validCardExpMonth: false,
            validCardExpYear: false,
         },
         renderSocialButton: false,
         newCreditCardDetail: {
            card_number: '',
            card_cvv: '',
            card_exp_month: '',
            card_exp_year: '',
         },
         isSocialSignup: false,
         userData:null,
      };
      this.years = this.getUpcomingYears();
   }

   getUpcomingYears() {
      let years = [];
      let current_year = new Date().getFullYear();
      for (let i = current_year; i < (current_year + 20); i++) {
         (years).push(i);
      }
      return years;
   }

   componentDidMount() {
      LoginService.getIpAddress().then(response => {
         let ip = response.data.ip;
         this.setState({
            ip: ip
         })
      })
   }

   /**
    * Function is use to set email input field changes
    */
   handleEmailChange = (event) => {
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors.blankEmail = false;
      fieldValidationErrors.validEmail = false;
      this.setState({
         email: event.target.value,
         formErrors: fieldValidationErrors
      });
   }

   /**
    * Function is use to set password input field changes
    */
   handlePasswordChange = (event) => {
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors.blankPassword = false;
      this.setState({
         password: event.target.value,
         formErrors: fieldValidationErrors
      });
   }

   /**
    * Function is use for validate the pattern.
    */
   handleNumberPattern = (number, type) => {
      let fieldValidationErrors = this.state.formErrors;
      if (number) {
         if (type === 'cardNumber') {
            let cardNumberPatternValid = number.match(/^[0-9a-zA-Z]*$/);
            if (!cardNumberPatternValid) {
               fieldValidationErrors.validCardNumber = true;
            }
         } else {
            let cardCvvPatternValid = number.match(/^[0-9]{3,4}$/);
            if (!cardCvvPatternValid) {
               fieldValidationErrors.validCardCVV = true;
            }
         }
      }
      this.setState({
         formErrors: fieldValidationErrors
      })
   }

   /**Function to validate card */
   validateCreditCard() {
      let cardDetail = this.state.newCreditCardDetail;
      let fieldValidationErrors = this.state.formErrors;
      let valid = true;
      if ((cardDetail.card_number === '') || (fieldValidationErrors.validCardNumber)) {
         valid = false;
      }
      if ((cardDetail.card_cvv === '') || (fieldValidationErrors.validCardCVV)) {
         valid = false;
      }
      if (cardDetail.card_exp_month === '') {
         valid = false;
      }
      if (cardDetail.card_exp_year === '') {
         valid = false;
      }
      return valid;
   }

   /**
    * Function is to submit the login values and to login user
    */
   handleSubmit = (event) => {
      event.preventDefault();
      const { email, password } = this.state
      if (email !== '' && FormValidationService.validateEmail(email) && password !== '') {
         this.setState({ loading: true })
         let loginData = {
            username: email,
            password: password,
         }
         LoginService.loginUser(loginData).then(response => {
            this.setState({ loading: false })
            if (response && response.api_status === 1) {
               toast.success("Successfully logged in.");
               let data = response.data
               localStorage.setItem('isUserLoggedIn', true);
               localStorage.setItem('currentUser', JSON.stringify(data));
               let path = '/admin';
               let domain = window.location.hostname;
               setTimeout(() => {
                  window.location.href = window.location.protocol + "//" + domain + path;
               }, 1500)
            } else {
               toast.error("Username or password is incorrect.");
            }
         });
      } else {
         this.validateField();
      }
   }

   /**
    * Function is use to login user using their social profiles(facebook, google)
    */
   handleSocialLogin = (user) => {
      this.setState({ loading: true });
      let postData = {
         registered_with: user._provider,
         first_name: user._profile.firstName,
         last_name: user._profile.lastName ? user._profile.lastName : '',
         email: user._profile.email,
         package: 'Growth-Yearly',
         ip: this.state.ip
      }
      LoginService.checkEmailExist(user._profile.email)
      .then(response => {
         if (response.api_status === 1) {
            this.setState({
               isSocialSignup:true,
               userData:postData,
               loading:false,
            });
         } else {
            this.submitForm(postData,'login');
         }
      })
   }

   /**Function to submit social form data */
   submitForm(userData,key) {
      LoginService.loginUserWithSocialAccounts(userData).then(response => {
         this.setState({loading: false});
         if (response) {
            if (response.api_status === 1) {
               toast.success('Successfully logged in.');
               let data = response.data;
               localStorage.setItem('isUserLoggedIn', true);
               localStorage.setItem('currentUser', JSON.stringify(data));
               let path = '/admin';
               let domain = window.location.hostname;
               setTimeout(() => {
                  window.location.href = window.location.protocol + "//" + domain + path;
               }, 1500)
               if(key === 'signup'){
                  window.fbq('track', 'CompleteRegistration');
               }
            } else {
               toast.error(response.message);
            }
         }
      });
   }

   /**
    * Function is use for validate the fields.
    */
   async validateField(key) {
      const { email, password ,newCreditCardDetail,formErrors} = this.state;
      let fieldValidationErrors = formErrors;
      if (key === 'validate_card') {
         if (newCreditCardDetail['card_number'] === '') {
            fieldValidationErrors.validCardNumber = true
         }
         if (newCreditCardDetail['card_cvv'] === '') {
            fieldValidationErrors.validCardCVV = true
         }
         if (newCreditCardDetail['card_exp_month'] === '') {
            fieldValidationErrors.validCardExpMonth = true
         }
         if (newCreditCardDetail['card_exp_year'] === '') {
            fieldValidationErrors.validCardExpYear = true
         }
      } else {
         if (email === '') { fieldValidationErrors.blankEmail = true; }
         if (email !== '' && FormValidationService.validateEmail(email) === null) {
            fieldValidationErrors.validEmail = true;
         }
         if (password === '') { fieldValidationErrors.blankPassword = true; }
      }
      await this.setState({
         formErrors: fieldValidationErrors
      });
   }

   /**
    * Function is use for handle the social login error case.
    */
   get socialLoginPorops() {
      return {
         onLoginFailure: this.socialLoginErrorCallback
      }
   }

   /**
    * Function is use for if get social login any error, re-render the render.
    */
   socialLoginErrorCallback = (err) => {
      this.setState({ renderSocialButton: true }, () => { this.setState({ renderSocialButton: false }) })
   }

   /**
    * Function to handle card detail change
    */
   handleChange(key,e,validationKey){
      let newCreditCardDetail = this.state.newCreditCardDetail;
      let formErrors = this.state.formErrors;
      if (formErrors[validationKey]){
         formErrors[validationKey] = false;
      }
      newCreditCardDetail[key] = e.target.value;
      this.setState({ newCreditCardDetail, formErrors});
   }

   /**
    * Function is to submit the card values and to login user
    */
   handleSaveCard = (e) =>{
      e.preventDefault();
      const { newCreditCardDetail,userData } = this.state;
      let data = userData;
      if (this.validateCreditCard()) {
         this.setState({loading:true});
         // live stripe key
         window.Stripe.setPublishableKey('pk_live_yYiGoHEd5PX653JPKD2nWDVz00s2ZuVmHl');
         // dev stripe key
         // window.Stripe.setPublishableKey('pk_test_rvWDLYCAQuLJhB9cvoDYwKMC00TLiYzidp');
         window.Stripe.card.createToken({
            number: newCreditCardDetail['card_number'],
            exp_month: newCreditCardDetail['card_exp_month'],
            exp_year: newCreditCardDetail['card_exp_year'],
            cvc: newCreditCardDetail['card_cvv']
         }, (status, response) => {
            if (status === 200) {
               let stripeToken = response.id;
               data.cc_token = stripeToken.toString();
               this.submitForm(data,'signup');
            } else {
               this.setState({ loading: false })
               toast.error(response.error.message);
            }
         });
      } else {
         this.validateField('validate_card');
      }
   }

   render() {
      const { email,newCreditCardDetail,isSocialSignup, password, formErrors, renderSocialButton, loading } = this.state;
      return (
         <>
            {
               loading ?
                  <div className="sh-loader-wrap d-flex justify-content-center align-items-center">
                     <Spinner className="sh-loader" color="primary" />
                  </div>
                  :
                  <div className="sh-login-wrapper">
                     <div className="logo-wrap">
                        <a href="/" className="d-inline-block">
                           <img alt="logo" className="logo" src={process.env.PUBLIC_URL + "/images/header-logo.png"} height="60" />
                        </a>
                     </div>
                     <LoginPreviewPanel />
                     <div className="sh-main-panel">
                        <div className="sh-panel-table">
                           <div className="sh-panel-table-cell">
                              <div className="d-flex align-items-center switch-wrap">
                                 <div className="res-logo">
                                    <a href="/" className="d-inline-block">
                                       <img alt="logo" src={process.env.PUBLIC_URL + "/images/saleshangry-logo.png"} height="50" />
                                    </a>
                                 </div>
                                 <div>
                                    <span className="fs-14 pr-3">Don’ t have an account? </span>
                                    <a href="/pricing" className="d-inline-block">
                                       <button className="btn rounded-btn primary-bdr-btn fs-14 text-space">get started</button>
                                    </a>
                                 </div>
                              </div>
                              <div className="mx-auto content-wrap">
                                 <div>
                                    <h4 className="font-normal">Sign in to SalesHangry.</h4>
                                    <p className="mb-5">Enter your details below.</p>
                                 </div>
                                 <Form>
                                    {!isSocialSignup ?
                                       <Fragment>
                                          <FormGroup className="input-group-lg">
                                             <Label for="emailId" className="text-uppercase">email address</Label>
                                             <Input type="email" autoComplete="off" value={email} onChange={this.handleEmailChange} placeholder="Email" id="emailId" />
                                             <span className="error">{formErrors.blankEmail && FormValidationService.emptyField()}</span>
                                             <span className="error">{formErrors.validEmail && FormValidationService.emptyField('email')}</span>
                                          </FormGroup>
                                          <FormGroup className="input-group-lg">
                                             <div className="d-flex justify-content-between align-items-center">
                                                <Label for="password" className="text-uppercase">password</Label>
                                                <a className="fs-14 text-primary" href="/login/forgot">Forgot Password ?</a>
                                             </div>
                                             <Input type="password" autoComplete="off" value={password} onChange={this.handlePasswordChange} placeholder="Password" id="passwordId" />
                                             <span className="error">{formErrors.blankPassword && FormValidationService.emptyField()}</span>
                                          </FormGroup>
                                          <div className="text-center mb-5 mt-4">
                                             <button
                                                className="btn btn-lg px-5 rounded-btn btn-primary fs-14 text-space"
                                                type="submit"
                                                onClick={this.handleSubmit}
                                             >
                                                login
                                             </button>
                                          </div>
                                       </Fragment>
                                       :
                                       <div className="billing-info-wrap">
                                          <span className="sec-title text-uppercase">Add Card</span>
                                          <FormGroup row>
                                             <Col sm={8} lg={8}>
                                                <Input
                                                   type="text"
                                                   autoComplete="off"
                                                   value={newCreditCardDetail['card_number']}
                                                   onChange={(e) => this.handleChange('card_number', e, 'validCardNumber')}
                                                   placeholder="Card Number"
                                                   id="cardNumber"
                                                   onBlur = {
                                                      () => this.handleNumberPattern(newCreditCardDetail['card_number'], 'cardNumber')
                                                   }
                                                />
                                                {formErrors.validCardNumber ? <span className="error">Please enter a valid card number.</span> : null}
                                             </Col>
                                             <Col sm={4} lg={4}>
                                                <Input
                                                   type="text"
                                                   autoComplete="off"
                                                   value={newCreditCardDetail['card_cvv']}
                                                   onChange={(e) => this.handleChange('card_cvv', e,'validCardCVV')}
                                                   placeholder="CVV" id="cvv"
                                                   onBlur = {
                                                      () => this.handleNumberPattern(newCreditCardDetail['card_cvv'],'cvvNumber')
                                                   }
                                                   className="mt-3 mt-sm-0"
                                                />
                                                {formErrors.validCardCVV ? <span className="error">Please enter a valid cvv.</span> : null}
                                             </Col>
                                          </FormGroup>
                                          <FormGroup row>
                                             <Col sm={6} lg={6}>
                                                <Label for="expMonth" className="text-uppercase">Exp. Month</Label>
                                                <CustomInput
                                                   onChange={(e) => this.handleChange('card_exp_month', e,'validCardExpMonth')} type="select"
                                                   name="select"
                                                   id="expMonth"
                                                   className="form-control"
                                                   value={newCreditCardDetail['card_exp_month']}
                                                >
                                                   <option disabled value="">Month</option>
                                                   {(this.months && (this.months.length > 0)) && this.months.map((month,key)=>{
                                                      if (month){
                                                         return (
                                                            <option key={key} value={month}>{month}</option>
                                                         )
                                                      } else {
                                                         return null;
                                                      }
                                                   })}
                                                </CustomInput>
                                                {formErrors.validCardExpMonth ? <span className="error">Please enter a valid month.</span> : null}
                                             </Col>
                                             <Col sm={6} lg={6}>
                                                <Label for="expYear" className="text-uppercase mt-sm-0 mt-3">Exp. Year</Label>
                                                <CustomInput
                                                   onChange={(e) => this.handleChange('card_exp_year', e,'validCardExpYear')}
                                                   type="select"
                                                   name="select"
                                                   id="expYear"
                                                   className="form-control"
                                                   value={newCreditCardDetail['card_exp_year']}
                                                >
                                                   <option disabled value="">Year</option>
                                                   {(this.years && (this.years.length > 0)) && this.years.map((year, key) => {
                                                      if (year) {
                                                         return (
                                                            <option key={key} value={year}>{year}</option>
                                                         )
                                                      } else {
                                                         return null;
                                                      }
                                                   })}
                                                </CustomInput>
                                                {formErrors.validCardExpYear ? <span className="error">Please enter a valid year.</span> : null}
                                             </Col>
                                          </FormGroup>
                                          <div className="text-center mb-5 mt-4">
                                             <button
                                                className="btn btn-lg px-5 rounded-btn btn-primary fs-14 text-space"
                                                type="submit"
                                                onClick={this.handleSaveCard}
                                             >
                                                Continue
                                             </button>
                                          </div>
                                       </div>
                                    }
                                 </Form>
                                 {!renderSocialButton &&
                                    <>
                                       {!isSocialSignup &&
                                          <div className="social-icons-wrap mb-4 text-center">
                                             <SocialButton
                                                className="btn-primary btn-lg fs-14 btn ml-sm-0 mr-sm-4 btn-shadow mb-sm-0 mb-4 mx-3"
                                                provider='facebook'
                                                appId={this.facebookAppId}
                                                onLoginSuccess={this.handleSocialLogin}
                                                {...this.socialLoginPorops}
                                             >
                                                <img className="pr-3 d-inline-block" src={process.env.PUBLIC_URL + "/images/facebook-icon.png"} alt="icon" height="26" />
                                                Login With Facebook
                                             </SocialButton>
                                             <SocialButton
                                                className="light-bg btn-lg fs-14 btn btn-shadow mb-4 mb-sm-0 mx-sm-0 mx-3"
                                                provider='google'
                                                appId={this.googleClientId}
                                                onLoginSuccess={this.handleSocialLogin}
                                                {...this.socialLoginPorops}
                                             >
                                                <img className="pr-3 d-inline-block" alt="icon" src={process.env.PUBLIC_URL + "/images/google-icon.png"} height="26" />
                                                Login With Google
                                             </SocialButton>
                                          </div>
                                       }
                                    </>
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
            }

         </>
      );
   }
}

export default Login;
