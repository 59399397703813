import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import * as Service from '../../Services/LoginService';
import * as FormValidationService from '../../Services/FormValidationService';
import { Button, Form, FormGroup, Label, Input, } from 'reactstrap';

class ForgotPassword extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loading: false,
         email: '',
         formErrors: { blankEmail: false, validEmail: false },
      };
   }

   /**
    * Function is use to set email input field changes
    */
   handleEmailChange = (event) => {
      let fieldValidationErrors = this.state.formErrors;
      fieldValidationErrors.blankEmail = false;
      fieldValidationErrors.validEmail = false;
      this.setState({
         email: event.target.value,
         formErrors: fieldValidationErrors
      });
   }

   /**
    * Function is use to send reset password email
    */
   onClickResetPasswordLink = (event) => {
      event.preventDefault();
      const { email } = this.state;
      if (email !== '' && FormValidationService.validateEmail(email)) {
         this.setState({ loading: true })
         let postData = {
            email: (this.state.email).toLowerCase(),
         }
         Service.onRequestResetPassword(postData).then(response => {
            this.setState({ loading: false })
            if (response.api_status === 1) {
               toast.success(response.message);
               this.props.history.push('/');
            } else {
               toast.error(response.message);
            }
         });
      } else {
         this.validateField();
      }
   }

   /**
   * Function is use for validate the fields.
   */
   async validateField() {
      const { email } = this.state;
      let fieldValidationErrors = this.state.formErrors;
      if (email === '') { fieldValidationErrors.blankEmail = true; }
      if (email !== '' && FormValidationService.validateEmail(email) === null) {
         fieldValidationErrors.validEmail = true;
      }
      await this.setState({
         formErrors: fieldValidationErrors
      });
   }

   render() {
      const { email, formErrors, loading } = this.state;
      return (
         <>
            {
               loading ?
                  <div className="sh-loader-wrap d-flex justify-content-center align-items-center">
                     <Spinner className="sh-loader" color="primary" />
                  </div>
                  :
                  <div className="sh-forget-password-wrap">
                     <div className="top-wrap d-flex justify-content-between align-items-center">
                        <div className="logo-wrap">
                           <a href="/" className="d-inline-block">
                              <img alt="logo" src={process.env.PUBLIC_URL + "/images/saleshangry-logo.png"} height="60" />
                           </a>
                        </div>
                        <div className="text-right switch-wrap">
                           <span className="fs-14 pr-3">Don' t have an account ? </span>
                           <a href="/pricing" className="d-inlline-block">
                              <Button className="btn primary-bdr-btn rounded-btn mt-1 fs-14 text-space">get started</Button>
                           </a>
                        </div>
                     </div>
                     <div className="sh-forget-content d-flex justify-content-center align-items-center h-100">
                        <div className="content-wrap mx-auto">
                           <div className="text-center">
                              <h4 className="font-normal">Reset Password</h4>
                              <p className="mb-5">Enter the email associated with your account and we will email you your reset instructions.</p>
                           </div>
                           <Form>
                              <FormGroup className="input-group-lg">
                                 <Label for="exampleFile" className="text-uppercase">email address</Label>
                                 <Input type="email" autoComplete="off" placeholder="Email" value={email} onChange={this.handleEmailChange} id="exampleFile" />
                                 <span>{formErrors.blankEmail && FormValidationService.emptyField()}</span>
                                 <span>{formErrors.validEmail && FormValidationService.emptyField('email')}</span>
                              </FormGroup>
                              <div className="text-center mb-5 mt-4">
                                 <button
                                    className="btn btn-lg px-5 mb-4 rounded-btn btn-primary fs-14 text-space"
                                    type="submit"
                                    onClick={this.onClickResetPasswordLink}
                                 >
                                    Reset Password Now
                     </button>
                                 <a className="back-to-login fs-14 d-block text-primary" href="/login">Back To Login</a>
                              </div>
                           </Form>
                        </div>
                     </div>
                  </div>
            }
         </>
      );
   }
}

export default ForgotPassword;
