import axios from 'axios';
const APIURL = 'https://saleshangry.com/shapi/v1';

/**
 * Function is use for get the IP address of your system.
 */
export function getIpAddress() {
    return axios.get('https://api.ipify.org/?format=json');
}

/**
 * Function to login user to their account, by using simple authentication
 * Params:: email , password
 * Return:: API response
 */
export async function loginUser(postData) {
   let headers = {
      'Token': await getCurrentUTCDateTimestamp()
   }
   return axios.post(APIURL + '/login', postData, { headers: headers })
      .then(function (response) {
         return response.data.response;
      })
      .catch(function (error) {
         console.log(error);
      });
}

// /**
//  * Function to login user using their social media accounts
//  * Params:: userData
//  * Return:: API response
//  */
// export async function loginUserWithSocialAccounts(userData) {
//    let headers = {
//       'Token': await getCurrentUTCDateTimestamp(),
//    }
//    return axios.post(APIURL + '/signup', userData, { headers: headers })
//       .then(function (response) {
//          return response.data.response;
//       })
//       .catch(function (error) {
//          console.log(error);
//       });
// }

/**
 * Function to return response of request reset password functionality
 * Params:: email
 * Return:: API response
 */
export async function onRequestResetPassword(email) {
   let headers = {
      'Token': await getCurrentUTCDateTimestamp()
   }
   return axios.post(APIURL + '/request-reset-password', email, { headers: headers })
      .then(function (response) {
         return response.data.response;
      })
      .catch(function (error) {
         console.log(error);
      });
}

/**
 * Function to return response of reset password functionality
 */
export async function resetPassword(data) {
   let headers = {
      'Token': await getCurrentUTCDateTimestamp()
   }
   return axios.post(APIURL + '/reset-password', data, { headers: headers })
      .then(function (response) {
         return response.data.response;
      })
      .catch(function (error) {
         console.log(error);
      });
}

/**
 * Function to login user to their account, by using token
 */
export async function loginByToken(loginToken) {
   let headers = {
      'Token': await getCurrentUTCDateTimestamp()
   }
   return axios.get(APIURL + '/ot-token-user/' + loginToken, { headers: headers })
      .then(function (response) {
         return response.data.response;
      })
      .catch(function (error) {
         console.log(error);
      });
}

function getCurrentUTCDateTimestamp() {
   let currentData = new Date();
   let newDate = new Date(Date.UTC(currentData.getUTCFullYear(), currentData.getUTCMonth(), currentData.getUTCDate(), currentData.getUTCHours(), currentData.getUTCMinutes(), currentData.getUTCSeconds()));
   let token = (newDate / 1000) * 440;

   return token;
}

/**
 * Function to login user using their social media accounts
 * Params:: userData
 * Return:: API response
 */
export async function loginUserWithSocialAccounts(userData) {
   let headers = {
      'Token': await getCurrentUTCDateTimestamp(),
   }
   return axios.post(APIURL + '/signup-with-card', userData, {
         headers: headers
      })
      .then(function (response) {
         return response.data.response;
      })
      .catch(function (error) {
         console.log(error);
      });
}

/**
 * Function is use for check the email is exist or not.
 * @param {*} email 
 */
export function checkEmailExist(email) {
   let params = {
      email: email
   }
   return axios.get(APIURL + '/verify-email', {
         params
      })
      .then(response => {
         return response.data.response;
      }).catch(error => {
         console.log(error);
      })
}



