import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './Login/Login';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import LoginByToken from './LoginByToken';

const Routes = props => (
   <Switch>
      <Route exact path='/' component={Login} />
      <Route path='/forgot' component={ForgotPassword} />
      <Route path='/reset-password' component={ResetPassword} />
      <Route path='/:token' component={LoginByToken} />
   </Switch>
);

export default Routes;
