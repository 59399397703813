import React from 'react';
import queryString from 'query-string';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import * as Service from '../../Services/LoginService';
import * as FormValidationService from '../../Services/FormValidationService';
import { Button, Form, FormGroup, Label, Input, } from 'reactstrap';

class ResetPassword extends React.Component {

   queryParams = null;

   constructor(props) {
      super(props);
      this.queryParams = queryString.parse(window.location.search)
      this.state = {
         loading: false,
         newPassword: '',
         confirmPassword: '',
         formErrors: {
            blankNewPassword: false,
            blankConfirmPassword: false,
            matchPassword: false,
            newPasswordLength: false
         },
      };
   }

   /**
    * Function is use for user fill the input field, set the state.
    */
   handleEventChange = (type) => (e) => {
      e.preventDefault();
      let fieldValidationErrors = this.state.formErrors;
      switch (type) {
         case "newPassword":
            fieldValidationErrors.blankNewPassword = false;
            fieldValidationErrors.newPasswordLength = false;
            fieldValidationErrors.matchPassword = false;
            break;
         case "confirmPassword":
            fieldValidationErrors.blankConfirmPassword = false;
            fieldValidationErrors.matchPassword = false;
            break;
         default:
            break;
      }

      this.setState({
         [type]: e.target.value,
         formErrors: fieldValidationErrors
      })
   }

   /**
	 * Function is use for match the new password and confirm password.
	 */
   matchPassword = (e) => {
      const { newPassword, confirmPassword } = this.state;
      let fieldValidationErrors = this.state.formErrors;
      if (newPassword !== confirmPassword) {
         fieldValidationErrors.matchPassword = true;
         this.setState({
            fieldValidationErrors
         })
      }
   }

   /**
	 * Function is use for submit the form validation,
	 * If validation is true.
	 */
   handleSubmit = (e) => {
      e.preventDefault();
      const { newPassword, confirmPassword, formErrors } = this.state;
      if (newPassword !== '' && confirmPassword !== '' && newPassword.length > 5 && !formErrors.matchPassword) {
         this.setState({ loading: true })
         let resetPasswordObject = {
            new_password: newPassword,
            password_reset_token: this.queryParams['token']
         }
         Service.resetPassword(resetPasswordObject)
            .then(response => {
               this.setState({ loading: false })
               if (response && response.api_status === 1) {
                  toast.success(response.message);
                  this.props.history.push('/');
               } else {
                  toast.error(response.message);
               }
            })
      } else {
         this.validateField();
      }
   }

   /**
    * Function is use for validate the fields.
    */
   async validateField() {
      const { newPassword, confirmPassword } = this.state;
      let fieldValidationErrors = this.state.formErrors;
      if (newPassword === '') { fieldValidationErrors.blankNewPassword = true; }
      if (confirmPassword === '') { fieldValidationErrors.blankConfirmPassword = true; }
      if (newPassword !== '' && newPassword.length < 6) {
         fieldValidationErrors.newPasswordLength = true;
      }
      await this.setState({
         formErrors: fieldValidationErrors
      });
   }

   render() {
      const { newPassword, confirmPassword, formErrors, loading } = this.state;
      return (
         <>
            {
               loading ?
                  <div className="sh-loader-wrap d-flex justify-content-center align-items-center">
                     <Spinner className="sh-loader" color="primary" />
                  </div>
                  :
                  <div className="sh-Reset-password-wrap">
                     <div className="top-wrap d-flex justify-content-between align-items-center">
                        <div className="logo-wrap">
                           <a href="/" className="d-inline-block">
                              <img alt="logo" src={process.env.PUBLIC_URL + "/images/saleshangry-logo.png"} height="60" />
                           </a>
                        </div>
                        <div className="text-right switch-wrap">
                           <span className="fs-14 pr-3">Don' t have an account ? </span>
                           <a href="/pricing" className="d-inline-block">
                              <Button className="btn primary-bdr-btn mt-1 rounded-btn fs-14 text-space">get started</Button>
                           </a>
                        </div>
                     </div>
                     <div className="sh-forget-content d-flex justify-content-center align-items-center h-100">
                        <div className="content-wrap mx-auto pt-4">
                           <div className="text-center">
                              <h4 className="font-normal">Reset Your Password</h4>
                              <p className="mb-5">Please enter and confirm your new password.</p>
                           </div>
                           <Form>
                              <FormGroup className="input-group-lg">
                                 <Label for="passwordId" className="text-uppercase">New Password</Label>
                                 <Input type="password" autoComplete="off" value={newPassword} onChange={this.handleEventChange('newPassword')} placeholder="New Password" id="passwordId" onBlur={(confirmPassword !== '') ? this.matchPassword : null} />
                                 <span>{formErrors.blankNewPassword && FormValidationService.emptyField()}</span>
                                 <span>{formErrors.newPasswordLength && FormValidationService.emptyField('passwordLength')}</span>
                              </FormGroup>
                              <FormGroup className="form-group">
                                 <Label for="confirmPasswordId" className="text-uppercase">Confirm Password</Label>
                                 <Input type="password" autoComplete="off" value={confirmPassword} onChange={this.handleEventChange("confirmPassword")} onBlur={(confirmPassword !== '') ? this.matchPassword : null} placeholder="Confirm Password" id="confirmPasswordId" />
                                 <span>{formErrors.blankConfirmPassword && FormValidationService.emptyField()}</span>
                                 <span>{formErrors.matchPassword && FormValidationService.emptyField('matchPassword')}</span>
                              </FormGroup>
                              <div className="text-center mb-5 mt-4">
                                 <button className="btn btn-lg px-5 mb-4 rounded-btn btn-primary fs-14 text-space" type="submit" onClick={this.handleSubmit} >
                                    Set New Password
                                 </button>
                                 <a className="back-to-login fs-14 d-block text-primary" href="/login">Back To Login</a>
                              </div>
                           </Form>
                        </div>
                     </div>
                  </div>
            }
         </>

      )
   }
}

export default ResetPassword;