import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './Routes/routing';
// css
import './lib/LoginCss.js';

const FontAwesomeCloseButton = ({ closeToast }) => (
   <i className="toastify__close fas fa-times Toastify__close-button" onClick={closeToast}></i>
);

ReactDOM.render(
   <BrowserRouter basename="/login">
      <Routes />
      <ToastContainer
         closeButton={<FontAwesomeCloseButton />}
         transition={Slide}
         autoClose={3000}
         hideProgressBar={true}
         position={toast.POSITION.TOP_RIGHT}
      />
   </BrowserRouter>,
   document.getElementById('root')
);
