/* 
* FormValidation Service contains all the functions required for form validation 
*/

import strings from '../Strings/en';

/**
* Function is use for check the email validation.
*/
export function validateEmail(email) {
   let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
   return emailValid;
}

/**
 * Function is use for show the specific error.
 */
export function emptyField(type = '') {
   switch (type) {
      case 'email':
         return (
            strings.emailValidationText
         )
      case 'password':
         return (
            strings.passwordValidationText
         )
      case 'matchPassword':
         return (strings.matchPassword)
      case 'passwordLength':
         return (strings.passwordLength)
      default:
         return (
            strings.emptyField
         )
   }
}

