/**
 * LoginByToken page
 */
import React from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import * as LoginService from '../../Services/LoginService';

class LoginByToken extends React.Component {

   componentDidMount() {
      let token = this.props.match.params.token;
      LoginService.loginByToken(token)
         .then(response => {
            if (response) {
               if (response.api_status === 1) {
                  toast.success("Successfully logged in.");
                  let data = response.data
                  localStorage.setItem('isUserLoggedIn', true);
                  localStorage.setItem('currentUser', JSON.stringify(data));
                  let path = '/admin';
                  let domain = window.location.hostname;
                  window.location.href = window.location.protocol + "//" + domain + path;
               } else {
                  toast.error(response.message);
               }
            }
         })
   }

   render() {
      return (
         <div className="sh-loader-wrap d-flex justify-content-center align-items-center">
            <Spinner className="sh-loader" color="primary" />
         </div>
      )
   }
}

export default LoginByToken;